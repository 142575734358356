document.addEventListener('DOMContentLoaded', function () {
  iniciaApp(); 
});

function iniciaApp() {
  accordion();
  crearGaleria();
  openServ();
  myFunction();

}

function accordion() {
  var acc = document.getElementsByClassName("container");
  var i;

  for (i = 0; i < acc.length; i++) {
    acc[i].addEventListener("click", function () {
      this.classList.toggle("active");
    });
  }
}

function openServ(evt, servName) {
  // Declare all variables
  var i, tabcontent, tablinks;
  // Get all elements with class="tabcontent" and hide them
  tabcontent = document.getElementsByClassName("tabcontent");
  for (i = 0; i < tabcontent.length; i++) {
    tabcontent[i].style.display = "none";
  }
  // Get all elements with class="tablinks" and remove the class "active"
  tablinks = document.getElementsByClassName("tablinks");
  for (i = 0; i < tablinks.length; i++) {
    tablinks[i].className = tablinks[i].className.replace("active", "");
  }

  // Show the current tab, and add an "active" class to the button that opened the tab
  document.getElementById(servName).style.display = "block";
  evt.currentTarget.className += " active";
}

function crearGaleria() {
  const galeria = document.querySelector('.galeria-imagenes');
  var i;
   for(let i = 1; i <= fin; i++ ) {
      const imagen = document.createElement('picture');
      imagen.innerHTML = `
          <source srcset="${folder}/img${i}.avif" type="image/avif">
          <source srcset="${folder}/img${i}.webp" type="image/webp">
          <img loading="lazy" width="200" height="300" src="${folder}/img${i}.jpg" alt="imagen galeria">
      `;
      imagen.onclick = function() {
          mostrarImagen(i);
      }

      galeria.appendChild(imagen);
  }
}  

function mostrarImagen(id) {
  const imagen = document.createElement('picture');
  
  imagen.innerHTML = `
      <source srcset="${folder}/img${id}.avif" type="image/avif">
      <source srcset="${folder}/img${id}.webp" type="image/webp">
      <img loading="lazy" width="200" height="300" src="${folder}/img${id}.jpg" alt="imagen galeria">
  `;

  // Crea el Overlay con la imagen
  const overlay = document.createElement('DIV');
  overlay.appendChild(imagen);
  overlay.classList.add('overlay');
  
  overlay.onclick = function() {
      const body = document.querySelector('body');
      body.classList.remove('fijar-body');
      overlay.remove();
  }

  // Boton para cerrar el Modal
  const cerrarModal = document.createElement('P');
  cerrarModal.textContent = 'X';
  cerrarModal.classList.add('btn-cerrar');
  cerrarModal.onclick = function() {
      const body = document.querySelector('body');
      body.classList.remove('fijar-body');
      overlay.remove();
  }
  overlay.appendChild(cerrarModal);

  // Añadirlo al HTML
  const body = document.querySelector('body');
  body.appendChild(overlay);
  body.classList.add('fijar-body');
}

function myFunction() {
  var x = document.getElementById("myTopnav");
  if (x.className === "topnav") {
      x.className += " responsive";
  } else {
      x.className = "topnav";
  }
}
